import { Dispatch, useEffect, useLayoutEffect } from "react"

import { usePlaid } from "../../../Helpers/Plaid"

import type { PlaidDataActionType } from "../../../Helpers/Reducers/PlaidData/types"
import { postMessageSender } from "../../../Helpers/PostMessage"
import "./style.css"

type PlaidProps = {
  token: string
  onEvent: Dispatch<PlaidDataActionType>
  onSuccess: (data: { linkSessionId: string; institutionId: string; publicToken: string }) => void
  onExit: () => void
}

export function Plaid(props: PlaidProps) {
  const { lastPlaidEvent, plaidOpen, plaidReady } = usePlaid(props.token)

  useEffect(() => {
    document.body?.style.setProperty("overflow", "auto", "important")

    if (lastPlaidEvent) {
      // removes plaid overflow:hidden that it forcibly sets to body

      props.onEvent({
        type: "ADD_EVENT",
        payload: { ...lastPlaidEvent },
      })

      switch (lastPlaidEvent.event.eventName) {
        case "SUBMIT_CREDENTIALS":
          postMessageSender({
            event: "marketing",
            payload: {
              status: "track",
              data: {
                event: "app_banking_plaid_submitted",
                properties: {
                  bankInstitution: lastPlaidEvent.raw?.institution_name,
                },
              },
            },
          })
          break
        case "EXIT":
          props.onExit()
          break
        case "SUCCESS":
          props.onSuccess({
            linkSessionId: lastPlaidEvent.raw?.link_session_id ?? "",
            institutionId: lastPlaidEvent.raw?.institution?.institution_id ?? "",
            publicToken: lastPlaidEvent.raw?.public_token ?? "",
          })
          break
        default:
          break
      }
    }
  }, [lastPlaidEvent])

  // init iframe
  useLayoutEffect(() => {
    if (!plaidReady) return

    plaidOpen()

    let plaidLinkRoot = document.getElementById("root")
    let plaidContainer = document.getElementById("plaid-container")
    let plaidIframe = document.getElementsByTagName("iframe")[0]
    plaidContainer?.appendChild(plaidIframe)

    if (plaidLinkRoot) {
      plaidLinkRoot.style.setProperty("display", "block", "important")
      // When application come from mobile, there is a display: none !important set by react-plaid-link. We reset to display: block.
    }
  }, [plaidReady])

  return <div id="plaid-container" className="h-[650px] sm:h-auto" />
}

import { Fragment, useContext, useState, useEffect, useCallback, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  AddressAutocomplete,
  AddressAutocompleteDetailsType,
  Button,
  DateInput,
  InputGroup,
  SelectInput,
  TextInput,
  PhoneInput,
} from "@brainfinance/icash-component-library"
import { Loader } from "../../Utilities/Loader"

import { listParser } from "../../../Helpers/Parsers/List"

import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"
import { employmentDetailsParser } from "../../../Helpers/Parsers/Steps/EmploymentDetails"

import { RequestsContext } from "../../../Helpers/Reducers/Requests"
import { getStepRequest, postStepRequest } from "../../../Helpers/Requests"
import { goToNext, goToPrevious } from "../../../Helpers/Routes"
import { StepsFormContainer } from "../../Utilities/StepsFormContainer"
import {
  convertCountryCodeToId,
  convertStateToId,
  isCanada,
  isUS,
} from "../../../Helpers/Parsers/Steps/ResidentialDetails"
import { toast } from "react-toastify"

import { employmentDetailsValidators } from "../../../Helpers/Validators/Steps/EmploymentDetails"
import { validateValues } from "../../../Helpers/Validators"
import { EmploymentDetailsType, InstantLoanStateType } from "../../../Helpers/Reducers/types"
import { postMessageSender } from "../../../Helpers/PostMessage"

// details cleanup is needed for submit, progress bar etc
export const employmentCleanupDetails = (details: EmploymentDetailsType) => {
  details.companyPhone = undefined
  details.employmentTitle ||= ""
  details.employerName ||= ""
  details.timeAtCurrentJob ||= ""

  // delete unused parts
  if (!hasAJob(details)) {
    let key: keyof typeof details
    for (key in details) if (key.startsWith("job")) details[key] = undefined
    // details.companyPhone = undefined
    details.employmentTitle = undefined
    if (hasTimeReceivingRevenueField(details)) {
      if (!getTimeReceivingRevenueFieldName(details)) details.employerName = undefined
    } else details.timeAtCurrentJob = undefined
  }
  if (hasEmploymentInsurance(details)) {
    details.employerName = undefined
  } else {
    details.startClaimDate = undefined
    details.endClaimDate = undefined
  }

  return details
}

export function EmploymentDetails() {
  const [keyReloadEmploymentAddress, setKeyReloadEmploymentAddress] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [lists, setLists] = useState({
    employmentTimeReceivingRevenue: [],
    employmentStatus: [],
    employmentSinceWhen: [],
    jobAddressCountry: [],
    jobAddressState: [],
  })

  const navigate = useNavigate()

  const {
    state: { employmentDetails, loanParameters, isApp },
    dispatch,
  } = useContext(InstantLoanContext)

  const {
    state: { token },
  } = useContext(RequestsContext)

  const { pathname } = useLocation()
  const path = pathname.substring(1)

  const [useManualAddress, setUseManualAddress] = useState(false)
  const toggleManualAddress = () => setUseManualAddress(!useManualAddress)

  useEffect(() => {
    if (
      errors.jobAddressAutocomplete === undefined &&
      Object.keys(errors).some((key) => key.startsWith("jobAddress"))
    ) {
      toggleManualAddress()
    }
  }, [errors])

  

  const handleValueChange =
    <T extends string | number | Date>(key: keyof typeof employmentDetails) =>
    (value?: T) => {
      setErrors({})
      dispatch({
        step: "employmentDetails",
        payload: employmentCleanupDetails({ ...employmentDetails, [key]: value }),
      })
    }

  const handleSubmit = () => {
    const employmentDetailsValues = employmentCleanupDetails(employmentDetailsParser(employmentDetails))
    let validationErrors = validateValues(
      employmentDetailsValues,
      employmentDetailsValidators as {}
    )
    if (validationErrors) {
      // scroll to first document error
      setTimeout(
        () =>
          document
            .querySelector(".global--input-error")
            ?.scrollIntoView({ behavior: "smooth", block: "center" })
      )
      return setErrors(validationErrors)
    }
    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_employment_information_submitted",
        },
      },
    })

    setIsSubmitting(true)
    postStepRequest(3, employmentDetailsValues, token)
      .then(() => {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_employment_information_completed",
            },
          },
        })
        goToNext(navigate, path)
      })
      .catch((e) => {
        toast(e.message || e, { type: "error" })
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
        setIsSubmitting(false)
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    getStepRequest(3, token)
      .then(({ data, list }) => {
        // initialize options for selects
        for (let name in list) list[name] = listParser(list[name])
        setLists(list)
        dispatch({
          step: "employmentDetails",
          payload: employmentCleanupDetails({
            ...data,
            startClaimDate: data.startClaimDate ? new Date(data.startClaimDate + "T00:00:00") : undefined,
            endClaimDate: data.endClaimDate ? new Date(data.endClaimDate + "T00:00:00") : undefined,
          }),
        })
      })
      .then(() => setIsLoading(false))
      .catch((e) => {
        toast(e.message || e, { type: "error" })
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
        setIsLoading(false)
      })
  }, [])

  const updateOldResidentialDetailsWithAutocompleteDetails = useCallback(
    (addressAutocompleteDetails: AddressAutocompleteDetailsType) => {
      if (addressAutocompleteDetails) {
        dispatch({
          step: "employmentDetails",
          payload: employmentCleanupDetails({
            ...employmentDetails,
            jobAddressPostalCode: addressAutocompleteDetails.postalCode ?? "",
            jobAddressCivicNumber: addressAutocompleteDetails.streetNumber ?? "",
            jobAddressStreet: addressAutocompleteDetails.route ?? "",
            jobAddressStateId: convertStateToId(addressAutocompleteDetails.stateCode) ?? "",
            jobAddressCountryId: convertCountryCodeToId(addressAutocompleteDetails.country) ?? "",
            jobAddressCity: addressAutocompleteDetails.locality ?? "",
          }),
        })
        setKeyReloadEmploymentAddress((i) => ++i)
      }
    },
    [employmentDetails]
  )

  useEffect(() => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "screen",
        data: {
          categoryName: "Application",
          pathName: "app_employment_information_loaded",
        },
      },
    })
  }, [])

  return (
    <Loader loading={isLoading || !token}>
      <StepsFormContainer>
        <p className="caption-medium mb-8" id="test-caption-employer">
          Please enter your employment details. Don’t worry, we won’t be contacting your employer.
          Our system simply needs to perform an automated validation of the information you provide.
        </p>
        <div
          className={`grid grid-cols-2 ${
            !hasAJob(employmentDetails) ? "sm:grid-cols-1" : ""
          } gap-x-[22px] mb-[22px] items-start sm:items-start`}
        >
          <SelectInput
            native={isApp ? false : undefined}
            label="Employment status"
            value={employmentDetails.employmentStatus}
            error={errors.employmentStatus}
            onChange={handleValueChange("employmentStatus")}
            options={lists.employmentStatus}
            id="test-employment-status"
            disabled={isSubmitting}
          />
          <div className="col-span-2 sm:col-span-1 mb-4 sm:mb-0" />

          {hasAJob(employmentDetails) ? (
            <Fragment>
              <TextInput
                label="Job title"
                value={employmentDetails.employmentTitle}
                error={errors.employmentTitle}
                onChange={handleValueChange("employmentTitle")}
                id="test-job-title"
                disabled={isSubmitting}
              />
              <TextInput
                label="Company name"
                value={employmentDetails.employerName}
                error={errors.employerName}
                onChange={handleValueChange("employerName")}
                id="test-company-name"
                disabled={isSubmitting}
              />
              <SelectInput
                native={isApp ? false : undefined}
                label="Time spent at this job"
                value={employmentDetails.timeAtCurrentJob}
                error={errors.timeAtCurrentJob}
                onChange={handleValueChange("timeAtCurrentJob")}
                options={lists.employmentSinceWhen}
                id="test-job-time-spent"
                disabled={isSubmitting}
              />
              <div />
              <InputGroup className="col-span-2 mb-10">
                <div className="flex flex-col m-[12px]">
                  <h3 className="subtitle-caption mt-2">Employment address</h3>
                  <AddressAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    label="Employment address"
                    defaultValue={employmentDetails.jobAddressAutocomplete}
                    onChange={handleValueChange("jobAddressAutocomplete")}
                    onChangeAddressDetails={(addressDetails: any) => {
                      updateOldResidentialDetailsWithAutocompleteDetails(addressDetails)
                    }}
                    error={errors.jobAddressAutocomplete}
                    id="test-autocomplete-job-address"
                  />
                  <p className="caption-medium mb-[32px]" id="test-job-address-manual">
                    Can't find your address? Manually input it&nbsp;
                    <span onClick={toggleManualAddress} className="underline cursor-pointer">
                      here
                    </span>
                    . Note that we don't accept PO box addresses.
                  </p>
                  {useManualAddress ? (
                    <Fragment key={keyReloadEmploymentAddress}>
                      <div className="col-span-2 grid grid-cols-10 gap-x-[22px]">
                        <TextInput
                          disabled={isSubmitting}
                          className="col-span-3 sm:col-span-4"
                          label="Civic number"
                          value={employmentDetails.jobAddressCivicNumber}
                          error={errors.jobAddressCivicNumber}
                          onChange={handleValueChange("jobAddressCivicNumber")}
                          id="test-job-civic-number"
                        />
                        <TextInput
                          className="col-span-4 sm:col-span-6"
                          label="Street"
                          value={employmentDetails.jobAddressStreet}
                          error={errors.jobAddressStreet}
                          onChange={handleValueChange("jobAddressStreet")}
                          id="test-job-street"
                          disabled={isSubmitting}
                        />
                        <TextInput
                          className="col-span-3  sm:col-span-10"
                          label="City"
                          value={employmentDetails.jobAddressCity}
                          error={errors.jobAddressCity}
                          onChange={handleValueChange("jobAddressCity")}
                          id="test-job-city"
                          disabled={isSubmitting}
                        />
                      </div>
                      <div className="col-span-2 grid grid-cols-10 gap-x-[22px]">
                        <SelectInput
                          native={isApp ? false : undefined}
                          className="col-span-3 sm:col-span-6"
                          label="Province"
                          value={employmentDetails.jobAddressStateId}
                          error={errors.jobAddressStateId}
                          onChange={handleValueChange("jobAddressStateId")}
                          options={
                            !isCanada(employmentDetails.jobAddressCountryId)
                              ? []
                              : lists.jobAddressState
                          }
                          placeHolder={
                            !isCanada(employmentDetails.jobAddressCountryId) ? " " : "Select"
                          }
                          disabled={!isCanada(employmentDetails.jobAddressCountryId)}
                          id="test-job-state"
                        />
                        <TextInput
                          className="col-span-3 mt-8 sm:col-span-4"
                          label="Postal code"
                          value={employmentDetails.jobAddressPostalCode}
                          error={errors.jobAddressPostalCode}
                          onChange={handleValueChange("jobAddressPostalCode")}
                          id="test-job-postal-code"
                          disabled={isSubmitting}
                        />
                        <SelectInput
                          native={isApp ? false : undefined}
                          className="col-span-4 sm:col-span-10"
                          label="Country"
                          value={employmentDetails.jobAddressCountryId}
                          error={errors.jobAddressCountryId}
                          onChange={handleValueChange("jobAddressCountryId")}
                          options={lists.jobAddressCountry}
                          id="test-job-country"
                          disabled={isSubmitting}
                        />
                      </div>
                      {/* <TextInput
                        optional
                        className="mt-8"
                        label="Apt / Suite / Unit #"
                        value={employmentDetails.jobAddressAptNumber}
                        error={errors.jobAddressAptNumber}
                        onChange={handleValueChange("jobAddressAptNumber")}
                        id="test-job-apt"
                        disabled={isSubmitting}
                      /> */}
                    </Fragment>
                  ) : (
                    // <TextInput
                    //   label="Suite number"
                    //   optional
                    //   value={employmentDetails.jobAddressAptNumber}
                    //   error={errors.jobAddressAptNumber}
                    //   onChange={handleValueChange("jobAddressAptNumber")}
                    //   id="test-job-apt-number"
                    //   disabled={isSubmitting}
                    // />
                    <></>
                  )}
                </div>
              </InputGroup>
              {/* <div>
                <PhoneInput
                  className="sm:mt-[11px] sm:mb-[22px]"
                  label="Company phone number"
                  value={employmentDetails.companyPhone}
                  error={errors.companyPhone}
                  onChange={handleValueChange("companyPhone")}
                  id="test-job-phone"
                  disabled={isSubmitting}
                />
              </div> */}
            </Fragment>
          ) : null}

          {hasTimeReceivingRevenueField(employmentDetails) && (
            <Fragment>
              {getTimeReceivingRevenueFieldName(employmentDetails) != null && (
                <TextInput
                  className="mt-8"
                  label={getTimeReceivingRevenueFieldName(employmentDetails) ?? ""}
                  value={employmentDetails.employerName}
                  error={errors.employerName}
                  onChange={handleValueChange("employerName")}
                  id="test-job-employer-name"
                  disabled={isSubmitting}
                />
              )}
              <SelectInput
                native={isApp ? false : undefined}
                label="Time receiving revenue"
                value={employmentDetails.timeAtCurrentJob}
                error={errors.timeAtCurrentJob}
                onChange={handleValueChange("timeAtCurrentJob")}
                options={lists.employmentTimeReceivingRevenue}
                id="test-job-time-revenue"
                disabled={isSubmitting}
              />
            </Fragment>
          )}

          {hasEmploymentInsurance(employmentDetails) ? (
            <Fragment>
              <DateInput
                label="Start claim date"
                value={employmentDetails.startClaimDate}
                error={errors.startClaimDate}
                placeHolder="Start claim date"
                id="test-job-start-claim"
                onChange={handleValueChange("startClaimDate")}
                disabled={isSubmitting}
              />
              <DateInput
                label="End claim date"
                value={employmentDetails.endClaimDate}
                error={errors.endClaimDate}
                placeHolder="End claim date"
                onChange={handleValueChange("endClaimDate")}
                id="test-job-end-claim"
                disabled={isSubmitting}
              />
            </Fragment>
          ) : null}
        </div>
        <div className="flex sm:space-x-0 flex-col">
          <p className="caption mb-[32px] sm:mb-[24px]">
            By clicking Continue, I confirm that the information provided is current and accurate.
          </p>
          <div className="flex space-x-[22px] sm:flex-col-reverse sm:space-x-0">
            <Button
              size="large"
              className="flex-1 sm:mt-[22px] sm:py-[13px] sm:text-[16px] sm:hidden"
              onClick={() => goToPrevious(navigate, path)}
              disabled={isSubmitting}
            >
              Previous step
            </Button>
            <Button
              size="large"
              className="flex-1 sm:py-[13px] sm:text-[16px]"
              appearance="primary"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={!!Object.values(errors).join("") || isSubmitting}
            >
              Continue
            </Button>
          </div>
        </div>
      </StepsFormContainer>
    </Loader>
  )
}

const getTimeReceivingRevenueFieldName = (
  employmentDetails: InstantLoanStateType["employmentDetails"]
) => {
  if (String(employmentDetails.employmentStatus) === "19") return "Pensioner name"
  if (String(employmentDetails.employmentStatus) === "20") return "Insurance company name"
  return null
}

export const hasAJob = (employmentDetails: InstantLoanStateType["employmentDetails"]) => {
  if (
    String(employmentDetails.employmentStatus) === "12" ||
    String(employmentDetails.employmentStatus) === "1" ||
    String(employmentDetails.employmentStatus) === "2" ||
    String(employmentDetails.employmentStatus) === "3" ||
    String(employmentDetails.employmentStatus) === "4" ||
    String(employmentDetails.employmentStatus) === "5"
  ) {
    return true
  }
  return false
}

export const hasTimeReceivingRevenueField = (
  employmentDetails: InstantLoanStateType["employmentDetails"]
) => {
  if (
    String(employmentDetails.employmentStatus) === "13" ||
    String(employmentDetails.employmentStatus) === "15" ||
    String(employmentDetails.employmentStatus) === "14" ||
    String(employmentDetails.employmentStatus) === "16" ||
    String(employmentDetails.employmentStatus) === "17" ||
    String(employmentDetails.employmentStatus) === "19" ||
    String(employmentDetails.employmentStatus) === "20" ||
    String(employmentDetails.employmentStatus) === "22" ||
    String(employmentDetails.employmentStatus) === "23"
  ) {
    return true
  }
  return false
}

export const hasEmploymentInsurance = (
  employmentDetails: InstantLoanStateType["employmentDetails"]
) => {
  return (
    String(employmentDetails.employmentStatus) === "9" ||
    String(employmentDetails.employmentStatus) === "18" ||
    String(employmentDetails.employmentStatus) === "21"
  )
}

import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate, useParams, Location } from "react-router-dom"
import { toast } from "react-toastify"
import { postMessageSender } from "../../../../Helpers/PostMessage"
import { FlinksDataStateType } from "../../../../Helpers/Reducers/FlinksData/types"
import { InstantLoanContext } from "../../../../Helpers/Reducers/InstantLoan"
import { PlaidDataActionType } from "../../../../Helpers/Reducers/PlaidData/types"
import { RequestsContext } from "../../../../Helpers/Reducers/Requests"
import { UserContext } from "../../../../Helpers/Reducers/User"
import { mariaFetch, postMarketingGroupSubscribe } from "../../../../Helpers/Requests"
import { goToNext } from "../../../../Helpers/Routes"
import { Flinks } from "../../../Utilities/Flinks"
import { Plaid } from "../../../Utilities/Plaid"
import { postFlinksDecision, postPlaidAssetReport, postPlaidEvents } from "../helpers"
import { clearRecentlyUsedInstitutions } from "../../../../Helpers/recentlyUsedInstitution"

type LocationWithState = Omit<Location, 'state'> & { state: { [key: string]: any } }

const config = {
  plaid: {
    clientName: String(process.env.REACT_APP_PLAID_CLIENT_NAME),
    env: String(process.env.REACT_APP_PLAID_ENV),
  },
  flinks: {
    flinksIframeURL: String(process.env.REACT_APP_FLINKS_URL),
  },
}

export default function BankAggregator() {
  const {
    state: { user },
  } = useContext(UserContext)
  const {
    state: { token },
  } = useContext(RequestsContext)
  const {
    state: { marketingConsentUpdated },
  } = useContext(InstantLoanContext)

  const navigate = useNavigate()
  const { pathname, search, state } = useLocation() as LocationWithState
  const { aggregator, id } = useParams()
  const plaidSuccessRef = useRef(false)
  const query = useMemo(() => new URLSearchParams(search), [search])
  const [plaidToken, setPlaidToken] = useState<string>('');

  useEffect(() => {
    if (state?.visited) {
      postMessageSender({
        event: "return"
      })

      return
    }
  }, [])

  useEffect(() => {
    document.querySelector("#scroll-anchor")?.scrollIntoView({ block: "start" })

    if (aggregator === "flinks") {
      postMessageSender({
        event: "marketing",
        payload: {
          status: "track",
          data: {
            event: "app_banking_flinks_loaded",
          },
        },
      })
    } else if (aggregator === "plaid") {
      postMessageSender({
        event: "marketing",
        payload: {
          status: "track",
          data: {
            event: "app_banking_plaid_loaded",
          },
        },
      })
    }
  }, [])

  useEffect(() => {

    if (!user || aggregator !== "plaid") return;

    const createLinkToken = async (attempts = 2) => {
      const plaidLinkTokenResponse = await mariaFetch(`/banking-session/plaidlinktoken`, token, JSON.stringify({
        userId: user.contactId ?? "",
        email: user.email ?? "",
        platform: 'web',
        source: 'application',
      })).catch(() => {
        if (!attempts) {
          console.error('can\'t create a link token. Please try again.')
          return;
        }
        console.error('can\'t create a link token. Retrying')
        return createLinkToken(attempts - 1);
      })

      if (plaidLinkTokenResponse) {
        const { linkToken } = await plaidLinkTokenResponse.json();
        setPlaidToken(linkToken);
      }

    }

    createLinkToken();
  }, [])

  const onPlaidEvent = async (data: PlaidDataActionType) => {
    if (data.type === "ADD_EVENT") {
      const event = {
        errorCode: data.payload.raw.error_code,
        errorMessage: data.payload.raw.error_message,
        eventName: data.payload.event.eventName,
        exitStatus: data.payload.raw.exit_status,
        institutionId: data.payload.raw.institution_id,
        institutionName: data.payload.raw.institution_name,
        institutionSearchQuery: data.payload.raw.institution_search_query,
        linkSessionId: data.payload.event.linkSessionId,
        requestId: data.payload.raw.request_id,
        timestamp: data.payload.event.timestamp,
        viewName: data.payload.event.viewName,
      }
      if (data.payload?.event?.linkSessionId !== undefined && data.payload?.event?.linkSessionId !== "") {
        await postPlaidEvents(token ?? "", {
          linkSessionId: data.payload.event.linkSessionId,
          events: [event],
          updateCode: query.get('updateCode')
        })
      }
    }
  }

  const onFlinksEvent = async (data: FlinksDataStateType) => {
    return await postFlinksDecision(token ?? "", { ...data, updateCode: query.get('updateCode') })
  }

  const handleExit = () => {
    navigate("/banking-details/search")
  }

  const handleFlinksSuccess = async () => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_banking_flinks_completed",
        },
      },
    })

    if (marketingConsentUpdated) {
      try {
        await postMarketingGroupSubscribe(token ?? "")
        postMessageSender({
          event: "marketing-group-updated",
          payload: {
            checked: true,
          },
        })
      } catch (error) { }
    }

    clearRecentlyUsedInstitutions(user?.contactId)
    navigate(location.pathname, { state: { ...state, visited: true }, replace: true })
    goToNext(navigate, pathname.slice(1))
  }

  const handlePlaidSuccess = async (data: { linkSessionId: string; institutionId: string; publicToken: string }) => {
    if (plaidSuccessRef.current) {
      return
    }
    plaidSuccessRef.current = true

    if (data.institutionId === "" || data.linkSessionId === "" || data.publicToken === "") {
      toast("An unknown error occurred, please retry.", { type: "error" })
      plaidSuccessRef.current = false
      return
    }

    const successAssetReport = await postPlaidAssetReport(token ?? "", data)
    if (!successAssetReport) {
      plaidSuccessRef.current = false
      navigate("/banking-details/search")
      return
    }

    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_banking_plaid_completed",
        },
      },
    })

    if (marketingConsentUpdated) {
      try {
        await postMarketingGroupSubscribe(token ?? "")
        postMessageSender({
          event: "marketing-group-updated",
          payload: {
            checked: true,
          },
        })
      } catch (error) { }
    }

    clearRecentlyUsedInstitutions(user?.contactId)
    navigate(location.pathname, { state: { ...state, visited: true }, replace: true })
    goToNext(navigate, pathname.slice(1))
  }

  return (
    <>
      {aggregator === "flinks" ? (
        <Flinks
          onStep={onFlinksEvent}
          src={config.flinks.flinksIframeURL}
          institutionId={id}
          clientEmail={user?.email ?? ""}
          clientId={user?.contactId ?? 0}
          onSuccess={handleFlinksSuccess}
          onExit={handleExit}
        />
      ) : null}
      {aggregator === "plaid" ?
        plaidToken ?
          (<Plaid
            onEvent={onPlaidEvent}
            onSuccess={handlePlaidSuccess}
            onExit={handleExit}
            token={plaidToken}
          />)
          : <div
            className="sm:absolute flex justify-center items-center left-0 right-0 z-10 sm:-mx-[16.5px] h-[650px] max-w-[622px]"
            style={{ maxHeight: "calc(100vh - 55px)" }}
          ><div className='loading-indicator'></div></div>
        : null}
    </>
  )
}
